<template>
  <v-sheet class="ma-8 mt-2 pa-4 rounded-lg">
    <v-card-text>

      <v-row v-if="isLoading">
        <v-col>
          <v-sheet class="pa-4 rounded-lg">
            <v-skeleton-loader type="table-heading, divider, text@3"></v-skeleton-loader>
          </v-sheet>
          <v-sheet class="pa-4 rounded-lg mt-4">
            <v-skeleton-loader type="table-heading, divider, text@3"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>

      <template v-if="!isLoading">

        <v-alert
            type="error"
            v-bind:key="key"
            v-for="(errorMessage, key) in errorMessages"
        >
          {{ errorMessage.detail }}
        </v-alert>

        <FormulateForm #default="{ isSaving }" @submit="submitForm">

          <h2 class="mb-5">Welke bundel wilt u?</h2>

          <v-card
              v-for="(bundle, key) in bundles"
              :key="key"
              @click="selectBundle(key)"
              outlined
              :class="['mb-4', selectedBundle === key ? 'selected-bundle' : '']"
          max-width="300px">
            <v-card-text>
              <h4 class="mb-2">{{ bundle.name }}</h4>
              <h2 class="mb-2">{{ bundle.pricePerCredit|formatPrice }} per credit</h2>
              <p class="mb-0">{{ bundle.price|formatPrice }}</p>
            </v-card-text>
          </v-card>

          <p>Prijzen zijn exclusief BTW. Credits zijn 2 jaar geldig.</p>

          <h2 class="mt-8 mb-5">Uw gegevens</h2>

          <FormulateInput
              label="Bedrijf"
              name="contact_company"
              v-model="contact_company"
              validation="required"
          />
          <v-row class="mb-3">
            <v-col cols="12" md="6" lg="4">
              <FormulateInput
                  label="Voornaam"
                  name="contact_firstname"
                  v-model="contact_firstname"
                  validation="required"
              />
            </v-col>
            <v-col>
              <FormulateInput
                  label="Achternaam"
                  name="contact_lastname"
                  v-model="contact_lastname"
                  validation="required"
              />
            </v-col>
          </v-row>
          <FormulateInput
              type="textarea"
              label="Straat + huisnummer"
              name="contact_street"
              v-model="contact_street"
              validation="required"
          />
          <FormulateInput
              type="textarea"
              label="Postcode"
              name="contact_postal_code"
              v-model="contact_postal_code"
              validation="required"
          />
          <FormulateInput
              type="textarea"
              label="Plaats"
              name="contact_city"
              v-model="contact_city"
              validation="required"
          />
          <FormulateInput
              type="email"
              label="E-mailadres"
              name="contact_email"
              v-model="contact_email"
              validation="required|email"
          />
          <FormulateInput
              label="BTW-nummer"
              name="contact_tax_number"
              v-model="contact_tax_number"
          />

          <FormulateInput
              type="checkbox"
              name="terms"
              label="Ik accepteer de algemene voorwaarden"
              v-model="terms_accepted"
              class="mb-2"
          />
          <p class="mt-0 text-caption">U kunt hier onze <a :href="pdfDownloadLink" target="_blank">algemene voorwaarden</a> (PDF) downloaden.</p>

          <FormulateInput
              input-class="v-btn v-size--default"
              type="submit"
              name="submit"
              :disabled="isSaving || !terms_accepted"
              :label="isSaving ? 'Laden...' : 'Koop credits'"
          />
        </FormulateForm>

      </template>

    </v-card-text>
  </v-sheet>
</template>

<script>
import axios from "@/plugins/axios";
// import router from "@/plugins/routes";

export default {
  props: {
    client_id: null,
  },
  data () {
    return {
      errorMessages: [],
      isLoading: true,
      isSaving: false,
      bundles: null,
      selectedBundle: null,
      contact_company: '',
      contact_firstname: '',
      contact_lastname: '',
      contact_street: '',
      contact_postal_code: '',
      contact_city: '',
      contact_email: '',
      contact_tax_number: '',
      terms_accepted: false,
      api_base_url: process.env.VUE_APP_API_BASEURL,
    }
  },
  computed: {
    pdfDownloadLink() {
      return this.api_base_url+'modules/custom/eform_clients/modules/eform_client_terms_and_conditions/downloads/algemene-voorwaarden-inclusief-verwerkersovereenkomst-als-addendum-e-formapp.pdf'
    },
  },
  mounted () {
    // Get registration from API
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi () {
      this.isLoading = true
      // Fetch results from the API
      axios
          .get('api/clients/'+this.client_id+'/credit-bundles')
          .then(response => {
            console.log(response.data);

            this.bundles = response.data;

            this.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.isLoading = false)
    },
    selectBundle(key) {
      this.selectedBundle = key
    },
    // Send the form to the API endpoint
    async submitForm() {

      this.isSaving = true;

      let payload = {
        bundle: this.selectedBundle,
        contact_company: this.contact_company,
        contact_firstname: this.contact_firstname,
        contact_lastname: this.contact_lastname,
        contact_street: this.contact_street,
        contact_postal_code: this.contact_postal_code,
        contact_city: this.contact_city,
        contact_email: this.contact_email,
        contact_tax_number: this.contact_tax_number,
      }

      axios.post('/api/clients/'+this.client_id+'/credit-buy', payload)
          .then(response => {
            this.isSaving = false
            this.$toast.success("Credits zijn gekocht.");

            // Redirect to Mollie URL
            if (response.data && response.data['checkout-url']) {
              window.location.href = response.data['checkout-url']
            }

          })
          .catch(error => {
            if (error.response && error.response.data) {
              // Show error message
              if (error.response.data.errors) {
                // Add error messages
                this.errorMessages = error.response.data.errors;
              }
            }
            this.errored = true
          })
          .finally(() => this.isSaving = false)
    }
  }
}
</script>

<style scoped>
  .selected-bundle {
    outline: 3px solid var(--v-primary-base);
  }
</style>