<template>
  <div>

    <v-data-table
        :headers="headers"
        :items="myRegistrations"
        class="mx-sm-8 rounded-lg"
        mobile-breakpoint="0"
        :page.sync="page"
        :loading="loading"
        hide-default-footer
        @click:row="rowClick"
    >
      <template #item.form_name="{ item }">
        {{ item.form_name }}
      </template>
      <template #item.form_summary="{ item }">
        <div
            class="form-summary"
            v-if="item.form_summary"
            v-html="replaceTokens(item.form_summary, getTokensFromSchema(item.schema), item.values, true, item.schema)"
        ></div>
      </template>
      <template #item.registration_date="{ item }">
        {{ item.registration_updated_at|formatDate }}
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
          :disabled="loading"
          v-model="page"
          :length="pageCount"
          :total-visible="9"
      ></v-pagination>
    </div>

    <v-container fluid class="pa-md-8 pt-md-2" v-if="myRegistrations.length === 0">
      <v-row>
        <v-col>
          U heeft nog geen registraties gedaan.
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="registrationModal" max-width="700" scrollable>
      <v-card>
        <v-card-title>Bekijk registratie</v-card-title>
        <v-card-text v-if="registration">
          <registration-view :registration="registration" :form="registration"></registration-view>
        </v-card-text>
        <v-card-actions>
          <v-btn depressed color="primary" @click="closeRegistrationModal">Sluit</v-btn>
          <v-btn outlined color="secondary" @click.stop="fillFormAgain(registration)">Opnieuw invullen <v-icon small class="ml-1">mdi-refresh</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import eformHelpers from "@/mixins/eformHelpers";
import axios from "axios";
import RegistrationView from "@/components/RegistrationView.vue";

export default {
  name: "MyRegistrationList",
  components: {
    RegistrationView,
  },
  mixins: [
    eformHelpers
  ],
  data () {
    return {
      myRegistrations: [],
      loading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      registrationModal: false,
      registration: null,
      headers: [
        {
          text: 'Formulier naam',
          sortable: false,
          align: 'start',
          value: 'form_name',
        },
        {
          text: 'Samenvatting',
          sortable: false,
          align: 'start',
          value: 'form_summary',
        },
        {
          text: 'Registratie datum',
          sortable: false,
          align: 'start',
          value: 'registration_date',
        },
      ],
    }
  },
  // Watch the page variable, load new results when it changes
  watch: {
    page (newPage) {
      // Load new results
      this.loadMyRegistrations(newPage)
    },
  },
  mounted () {
    // Load the first page
    this.loadMyRegistrations(0)
  },
  methods: {
    rowClick(item) {
      this.openRegistrationModal(item)
    },
    fillFormAgain(item) {
      // Open the form_fill route and prefill it with the registration values
      this.$router.push({ name: 'form_fill', params: {form_id: item.form_id, force_reload: '0', registration_id: item._id} })
    },
    async loadMyRegistrations(page = 0) {
      let config = {
        params: {
          'page': page,
          // 'sort': '-registration_date', // Sort in descending order by appending a minus
        }
      }
      this.loading = true
      // Fetch results form the API
      await axios
          .get('api/my-registrations', config)
          .then(response => {
            this.myRegistrations = response.data.registrations
            this.pageCount = response.data.pages_count;
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    openRegistrationModal(item) {
      this.registration = item
      this.registrationModal = true
    },
    closeRegistrationModal() {
      this.registration = null
      this.registrationModal = false
    },
  },
}
</script>

<style scoped>

</style>