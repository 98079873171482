<template>
  <div>
    <FormulateInput
        :uploader="customUploader"
        :capture="capture"
        :disabled="disabled"
        :is-admin-mode="isAdminMode"
        :key="element.uuid"
        v-bind="element"
    />
  </div>
</template>

<script>
import eformHelpers from "@/mixins/eformHelpers";
import store from "@/plugins/store";
import {fileToBase64} from "@/plugins/fileUploadHelper";
import axios from "axios";
import _ from "lodash";

export default {
  mixins: [
    eformHelpers,
  ],
  props: {
    element: Object,
    values: Object,
    schema: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    isAdminMode: {
      type: Boolean,
      default: false,
    },
    formSettings: Object,
  },
  computed: {
    capture() {
      return _.get(this.element, ['useOnlyCamera']) ? 'capture' : false;
    },
    uploadUrl() {
      if (this.hdImages) {
        return '/api/file-upload/form-hd';
      }
      return '/api/file-upload/form';
    },
    badInternetMode() {
      return _.get(this.formSettings, ['bad_internet_mode']) === true
    },
    hdImages() {
      return _.get(this.element, ['hdImages']) === true
    },
    appOnline() {
      return store.getters.appOnline
    }
  },
  methods: {
    /**
     * Custom uploader, this overrides the default uploader of Vue Formulate.
     *
     * @param file
     * @param progress
     * @param error
     * @returns {Promise<undefined|[{url: string, base64: unknown, filename: *}]|any>}
     */
    async customUploader (file, progress, error) {
      try {

        if (!this.appOnline || this.badInternetMode) {
          // App is offline, or badInternetMode is enabled. Convert file to base64.
          console.log('File upload started (to base64). Filename: ' + file.name)
          let converted_file = fileToBase64(file, this.hdImages)
          console.log('File upload completed (to base64). Filename: ' + file.name)
          return converted_file;
        }

        // App is online. Upload file with axios to backend.
        console.log('File upload started (to server). Filename: ' + file.name)
        const formData = new FormData()
        formData.append('file', file)
        const axiosResult = await axios.post(this.uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            // Set progress to process callback
            progress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          }
        })
        console.log('File upload completed (to server). Filename: ' + file.name)
        return await axiosResult.data

      }
      catch (err) {
        console.log(err)
        let filename = file.name ? file.name : 'unknown'
        error('Bestand kon niet geupload worden. Probeer het opnieuw. Filename: ' + filename)
      }
    }
  }

}
</script>

<style lang="scss" scoped>

</style>