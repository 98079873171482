const fileToBase64 = async function (file, is_hd) {

    // Convert file to base64
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    // Make sure we got no errors
    let base64result = await toBase64(file).catch(e => Error(e));
    if (base64result instanceof Error) {
        console.log('Error: ', base64result.message);
        return;
    }

    // Check if file is an image
    if (
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/gif' ||
        file.type === 'image/bmp'
    ) {
        if (is_hd) {
            // Resize the base64 encoded file
            base64result = await resizedataURL(base64result, 4000, 4000);
        }
        else {
            // Resize the base64 encoded file
            base64result = await resizedataURL(base64result, 2000, 2000);
        }
    }

    // Return file to Formulate
    return [{
        url: '/'+file.name,
        base64: base64result,
        filename: file.name
    }]
}

const resizedataURL = function resizedataURL(datas, maxW, maxH){
    return new Promise(function(resolve){

        // We create an image to receive the Data URI
        let img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function() {
            // We create a canvas and get its context.
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            // We set the dimensions at the wanted size.
            let iw = img.width;
            let ih = img.height;
            let iwScaled = iw;
            let ihScaled = ih;

            if (iw > maxW || ih > maxH) {
                let scale = Math.min((maxW/iw),(maxH/ih));
                iwScaled = iw*scale;
                ihScaled = ih*scale;
            }

            canvas.width = iwScaled;
            canvas.height = ihScaled;

            // We resize the image with the canvas method drawImage();
            ctx.drawImage(img,0,0, iwScaled, ihScaled);

            let dataURI = canvas.toDataURL("image/jpeg", 0.7);

            // This is the return of the Promise
            resolve(dataURI);
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;

    })
}

module.exports = {
    fileToBase64,
    resizedataURL,
};
