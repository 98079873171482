<template>
  <v-sheet class="pa-4 rounded-lg">
    <v-container>
      <v-row>
        <v-col>
          <h4>Registratiedatum</h4>
          <div class="value">{{ registration.registration_date|formatDate }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Serienummer</h4>
          <div class="value">{{ registration.registration_serial_number }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Gebruiker</h4>
          <div class="value">{{ registration.registration_user_id.username }}</div>
        </v-col>
      </v-row>
      <v-row v-if="registration.form_type === 'workflow'">
        <v-col>
          <h4>Fase</h4>
          <div class="value">{{ registration.registration_phase }}</div>
        </v-col>
      </v-row>
      <eform-registration-formatter :schema="schema" :registration-values="registration.values"></eform-registration-formatter>
    </v-container>
  </v-sheet>
</template>

<script>
import EformRegistrationFormatter from "@/components/EformRegistrationFormatter";
import userHelpers from "@/mixins/userHelpers";

export default {
  name: "RegistrationView",
  components: {
    EformRegistrationFormatter
  },
  mixins: [
      userHelpers
  ],
  props: {
    registration: Object,
    form: Object,
  },
  computed: {
    schema() {
      return this.form.schema
    },
  },
}
</script>

<style lang="scss" scoped>

</style>